import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-crud-vehiculo',
  templateUrl: './crud-vehiculo.component.html',
  styleUrls: ['./crud-vehiculo.component.css']
})
export class CrudVehiculoComponent implements OnInit  {

  imageChangedEvent: any = '';
  croppedImage: any = '';

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(image: string) {
    this.croppedImage = image;
  }
  imageLoaded() {
    // show cropper
  }
  loadImageFailed() {
    // show message
  }
  constructor() { }

  //Variables para uso del dropdown multiselect
  anios=[];
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};

  //Variables de fuente para nombre de vehiculo
  vehiculo = {
    marca: {
      id:null,
      nombre: ""
    },
    modelo: {},
    nombre : "valor inicial",
  };

  ngOnInit() { 
    this.anios = [
      { item_id: 1990, item_text: '1990' },
      { item_id: 1991, item_text: '1991' },
      { item_id: 1992, item_text: '1992' },
      { item_id: 1993, item_text: '1993' },
      { item_id: 1994, item_text: '1994' }
    ];
    this.selectedItems = [
    ];
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
   }
  onItemSelect(item: any) {
    this.selectedItems.push(item);
  }
  onSelectAll(items: any) {
    //console.log(items);
  }

  onSelectBrand($event){
    console.log('se ejecuta el evento');
    this.vehiculo.nombre = this.vehiculo.marca.nombre ;
  }
  onSelectModel(value: string) {
    this.vehiculo.nombre += value;
  }
}
