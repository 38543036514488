import { Injectable } from '@angular/core';
import { Http, RequestOptions, Headers, Response, RequestMethod } from '@angular/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { SETTINGS } from '../app.const';
import { LoginResultModel } from './LoginResultModel';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  token: string;
  constructor(private http: Http) { }

  private extractData(res: Response, username) {
    const body = res.json();
    localStorage.setItem('isLoggedin', 'true');
    localStorage.setItem('token', body.access);
    localStorage.setItem('username', username);
    return body.data || {};
  }

  private handleError(error: Response | any) {
    // In a real world app, you might use a remote logging infrastructure
    let errMsg: string;
    if (error instanceof Response) {
      // const body = error.json() || '';
      // const err = body.error || JSON.stringify(body);
      // errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
      errMsg = `${error.status} - ${error.statusText || ''}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    console.error(errMsg);
    return Observable.throw(errMsg);
  }


  getTokenAuthentication(email, password): Observable<string> {
    const body = `email=${email}&password=${password}`;
    const url = `${SETTINGS.domain_google }api/token/`;
    const headers = new Headers({ 'Content-Type': 'application/json',
      'Postman-Token': '9199478e-45aa-45f8-84e6-d7ebbcca4519'
                                 });
    const options = new RequestOptions({ headers: headers });
    return this.http.post(url, body, options)
      .pipe(map(res => this.extractData(res, email)))
      //.catchError(this.handleError);
  }

}
