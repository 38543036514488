import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { PublishService } from './publish.service';
import { VehicleTypeModel } from './vehicle-type-model'

@Component({
  selector: 'app-publish',
  templateUrl: './publish.component.html',
  styleUrls: ['./publish.component.css']
})
export class PublishComponent implements OnInit {

  /* vehicleType = {
    id: '1234',
    nombre: 'Autos'
  }; */
  /* vehicleTypeList = [
    {
      "nombre": "Motorhomes",
      "id": 7,
      "imagen": null
    },
    {
      "nombre": "Motos",
      "id": 8,
      "imagen": null
    }
  ] */
  //password = 'alfredo';
  //vehicleTypeList: Observable<VehicleTypeModel>
  vehicleTypeList= [];
  vehicleTypeSelected= 0;

  constructor(private publishService:PublishService) { }

  ngOnInit() {

    this.showVehicleType()
  }

  /* showVehicleType(){
    return this.publishService.getVehiclesTypes().subscribe((res.json(): any[]) => {
        console.log(res)
        this.vehicleTypeList = res;
      }) 
  } */

  showVehicleType(){
    this.publishService.getVehiclesTypes().subscribe(response => {
      console.log(response.json());
      if (response) {
        const body = response.json()
        this.vehicleTypeList = body
        //console.log(body.data || [])
      }
    },
    response => {
      console.log('da error el suscriber');
      console.log(response.error);
    }) 
  }

}
