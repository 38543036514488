import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CrudVehiculoComponent } from '../crud-vehiculo/crud-vehiculo.component';
import { HomeComponent } from '../home/home.component';
import { LoginComponent } from '../login/login.component';
import { RegisterComponent } from '../user/register/register.component';
import { NotFoundComponent } from '../system/not-found/not-found.component';
import { UnauthorizedComponent } from '../system/unauthorized/unauthorized.component';
import { ContactUsComponent } from '../contact-us/contact-us.component';
import { MiMenuComponent } from '../user/mi-menu/mi-menu.component';
import { MyProfileComponent } from '../user/my-profile/my-profile.component';
import { ChangePasswordComponent } from '../user/change-password/change-password.component';
import { AuthGuardService as AuthGuard } from '../auth/auth-guard.service';
import { DashboardComponent } from '../user/dashboard/dashboard.component';
import { SearchPartsComponent } from '../search-parts/search-parts.component';
import { SearchServiceComponent } from '../search-service/search-service.component';
import { PublishComponent } from '../publish/publish.component';
import { MyAddressComponent } from '../user/my-address/my-address.component';
import { NewPartComponent } from '../parts/new-part/new-part.component';
import { PartDetailComponent } from '../parts/part-detail/part-detail.component';
import { MyPublishComponent } from '../user/my-publish/my-publish.component';
import { SuperDashboardComponent } from '../user/super-dashboard/super-dashboard.component';
import { VehiclesComponent } from '../user-admin/vehicles/vehicles.component';

export const routes: Routes = [
    {
        path: 'crud-vehiculo',
        component: CrudVehiculoComponent,
        //canActivate: [AuthGuard]
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'dashboard',
        component: DashboardComponent,
        //canActivate: [AuthGuard]
    },
    {
        path: 'register', 
        component: RegisterComponent
    },
    {
        path: 'not-found',
        component: NotFoundComponent
    },
    {
        path: 'unauthorized',
        component: UnauthorizedComponent
    },
    {
        path: 'contact-us',
        component: ContactUsComponent,
    },
    {
        path: 'mi-menu',
        component: MiMenuComponent
    },
    {
        path: 'my-profile',
        component: MyProfileComponent,
        //canActivate: [AuthGuard] 
    },
    {
        path: 'change-password',
        component: ChangePasswordComponent,
        //canActivate: [AuthGuard] 
    },
    {
        path: '',
        component: HomeComponent
    },
    {
        path: 'search-parts',
        component: SearchPartsComponent
    },
    {
        path: 'search-service',
        component: SearchServiceComponent
    },
    {
        path: 'publish',
        component: PublishComponent
    },
    {
        path: 'my-address',
        component: MyAddressComponent
    },
    {
        path: 'new-part',
        component: NewPartComponent
    },
    {
        path: 'part-detail',
        component: PartDetailComponent
    },
    {
        path: 'my-publish',
        component: MyPublishComponent
    },
    {
        path: 'super-dashboard',
        component: SuperDashboardComponent,
    },
    {
        path: 'vehicles',
        component: VehiclesComponent,
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes)
    ],
    exports: [
        RouterModule
    ],
    declarations: []
})
export class AppRoutingModule { }
