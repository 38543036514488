import { Injectable } from '@angular/core';
//import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Http } from '@angular/http';
import { VehicleTypeModel } from './vehicle-type-model'
import { Observable, throwError } from 'rxjs';
import { map, catchError, retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PublishService {

  endPoint = 'http://150.136.171.108:9000/Vehicle/type';
  //endPoint = 'http://localhost:9000/Vehicle/type';

  constructor(private httpClient: Http) { }

  /* httpHeader = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  } */
 
  /* getVehiclesTypes(): Observable<VehicleTypeModel> {
    //return this.httpClient.get<VehicleTypeModel>(this.endPoint + '/users')
    return this.httpClient.get<VehicleTypeModel>(this.endPoint)
    .pipe(
      retry(1),
      //catchError(this.httpError)
    )
  } */

  getVehiclesTypes(){
    return this.httpClient.get('http://150.136.171.108:9000/Vehicle/type')
    //return this.httpClient.get(this.endPoint)
  }

  /* httpError(error) {
    let msg = '';
    if(error.error instanceof ErrorEvent) {
      // client side error
      msg = error.error.message;
    } else {
      // server side error
      msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(msg);
    return throwError(msg);
  } */
}
