import { Component, OnInit } from '@angular/core';
import { LoginService } from './login.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  username = 'alfredo';
  password = 'alfredo';

  constructor(private login: LoginService, private router: Router) {
  }
  
  tryLogin() {
    this.login.getTokenAuthentication(this.username,this.password).subscribe(
        response => {
          console.log(response);
          console.log('hizo la consulta');
          if (response) {
            //this.login.getTokenAuthentication(response.token);
            //Aqui consulta el token, en teoria ahora se comprobaria el autorizacion
            //this.router.navigateByUrl('/dasboard');
          }
        },
        response => {
          //alert(response.error);
          console.log('da error el suscriber');
        });
  }

  /* constructor(private api: ApiService, private login: LoginService, private router: Router) {
  }

  tryLogin() {
    this.api.login(
      this.email,
      this.password
    )
      .subscribe(
        response => {
          console.log(response);
          if (response.token) {
            this.login.setToken(response.token);
            this.router.navigateByUrl('/my-profile');
          }
        },
        response => {
          //alert(response.error);
          console.log('da error el suscriber');
        });
  } */

  ngOnInit() {
  }

}
