import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { CrudVehiculoComponent } from './crud-vehiculo/crud-vehiculo.component';
import { AppRoutingModule } from './app-routing/app-routing.module';
import { HomeComponent } from './home/home.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './user/register/register.component';
import { NotFoundComponent } from './system/not-found/not-found.component';
import { UnauthorizedComponent } from './system/unauthorized/unauthorized.component';
import { MiMenuComponent } from './user/mi-menu/mi-menu.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { ChangePasswordComponent } from './user/change-password/change-password.component';
import { MyProfileComponent } from './user/my-profile/my-profile.component';
import { AuthGuardService } from './auth/auth-guard.service';
import { AuthService } from './auth/auth.service';
import { JwtHelperService, JwtModule } from '@auth0/angular-jwt';
import { HttpModule } from '@angular/http'
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { DashboardComponent } from './user/dashboard/dashboard.component';
import { SearchPartsComponent } from './search-parts/search-parts.component';
import { SearchServiceComponent } from './search-service/search-service.component';
import { PublishComponent } from './publish/publish.component';
import { MyAddressComponent } from './user/my-address/my-address.component';
import { NewPartComponent } from './parts/new-part/new-part.component';
import { PartDetailComponent } from './parts/part-detail/part-detail.component';
import { MyPublishComponent } from './user/my-publish/my-publish.component';
import { SuperDashboardComponent } from './user/super-dashboard/super-dashboard.component';
import { MessagesComponent } from './system/messages/messages.component';
import { RechargeComponent } from './user/recharge/recharge.component';
import { VehiclesComponent } from './user-admin/vehicles/vehicles.component';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    CrudVehiculoComponent,
    HomeComponent,
    LoginComponent,
    RegisterComponent,
    NotFoundComponent,
    UnauthorizedComponent,
    MiMenuComponent,
    ContactUsComponent,
    ChangePasswordComponent,
    MyProfileComponent,
    DashboardComponent,
    SearchPartsComponent,
    SearchServiceComponent,
    PublishComponent,
    MyAddressComponent,
    NewPartComponent,
    PartDetailComponent,
    MyPublishComponent,
    SuperDashboardComponent,
    MessagesComponent,
    RechargeComponent,
    VehiclesComponent
  ],
  imports: [
    BrowserModule,
    HttpModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ImageCropperModule,
    NgMultiSelectDropDownModule.forRoot(),
    FormsModule,
    JwtModule.forRoot({})
  ],
  providers: [AuthService,AuthGuardService,JwtHelperService,HttpClient],
  bootstrap: [AppComponent, HeaderComponent, FooterComponent]
})
export class AppModule { }
